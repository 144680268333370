<template>
  <div class="col-lg-auto col ps-0 pe-0 px-lg-2 order-lg-3">
    <div class="gold-text text-center bold my-2 mt-lg-0">
      Donation Total
    </div>
    <div class="total medium px-1 text-center">
      {{displayTotalAndCurrency}}
    </div>
  </div>
</template>

<script>

export default {
  name: 'Show total',
  props: ['displayTotalAndCurrency']
}
</script>

<style scoped>

.total {
  font-size: 40px;
  color: #fff;
  line-height: 1;
}
</style>
